/* You can add global styles to this file, and also import other style files */

html,
body {
    width: 100vw;
    background-color: #121212;
    // background-color: #fff;
    color: white;
    margin: 0;
    padding: 0;
    direction: rtl;
}
body {
    overflow-x: hidden;
    font-family: "Varela Round", sans-serif;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
    text-decoration: none;
}
